<template>
    <b-card no-body >
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
                <b-icon icon="search" aria-hidden="true"></b-icon>
                Filtro de busqueda
            </b-button>
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <b-row>        
                        <b-col cols="12" md="4">
                            <label for="pedidoId">Identificador</label>
                            <b-form-input id="pedidoId" v-model="filter.pedidoId" @keydown.enter.prevent="$emit('get-pedidos')" placeholder="Identificador"></b-form-input>
                        </b-col>                    
                        <b-col cols="12" md="4">
                            <label for="date-from">Desde</label>
                            <b-form-datepicker placeholder="Seleccione Desde" id="date-from" v-model="filter.dateFrom"></b-form-datepicker>
                        </b-col>
                        <b-col cols="12" md="4">
                            <label for="date-to">Hasta</label>
                            <b-form-datepicker id="date-to" v-model="filter.dateTo"></b-form-datepicker>
                        </b-col>
                        <b-col cols="12" md="12" class="pt-2">
                            <div >
                                <b-button variant="info" @click.prevent="$emit('get-pedidos')" class="mx-1">
                                    <b-icon icon="search" aria-hidden="true"></b-icon>
                                    Buscar
                                </b-button>
                                <b-button variant="warning" @click.prevent="$emit('clear-filter-form')" class="mx-1 text-white">
                                    <b-icon icon="backspace" aria-hidden="true"></b-icon>
                                    Limpiar
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
    
</template>

<script>
export default {
    name: "FilterForm",
    props: {
        filter: {
            type: Object
        }
    }
};

</script>
<style scoped>

</style>