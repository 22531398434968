<template>
    <b-card header-tag="header" footer-tag="footer" class="mt-8">
        <b-card-text>
            <p class="mb-0" style="font-size: 21px">Listado de Pedidos</p>
        </b-card-text>
        <b-card-text>
            <b-table 
                responsive
                :fields="fields" 
                borderless
                :items="pedidos">
                <template v-slot:cell(id)="data">
                    {{ data.item.id }}
                </template>
                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at }}
                </template>
                <template v-slot:cell(nombre_departamento_pedido)="data">
                    {{ data.item.nombre_departamento_pedido }}
                </template>
                <template v-slot:cell(nombre_ciudad_pedido)="data">
                    {{ data.item.nombre_ciudad_pedido }}
                </template>
                <template v-slot:cell(total_precio)="data">
                    $ {{numberFormat(data.item.total_precio) }}
                </template>
                <template v-slot:cell(actions)="{ item }">
                    <b-button size="sm" variant="info" v-b-tooltip.hover title="Ver" class="text-white" 
                    :to="{name: 'InfoPedidoClienteWeb', params:{ id: item.id}}">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <template v-if="pedidos.length < 1">
                <b-alert show variant="danger">
                    Lo sentimos, no se han encontrado resultados para su busqueda.
                </b-alert>
            </template>
        </b-card-text>
        <b-card-text>
            <div class="mt-3">
                <b-row>                            
                    <b-col cols="12" md="4">
                        <b-pagination 
                            size="md" 
                            pills 
                            :total-rows="paginator.totalPage" 
                            v-model="paginator.page" 
                            :per-page="1" 
                            @input="changePage" 
                            first-number 
                            last-number>
                        </b-pagination>
                    </b-col>
                    <b-col cols="12" sm="2">
                        <b-form-select @change="updatePageSize()" v-model="paginator.pageSize" :options="pageSize" ></b-form-select>
                    </b-col>
                </b-row>
            </div>
        </b-card-text>
    </b-card>
</template>

<script>
export default {
    name: "PedidosTable",
    props: {
        pedidos: {
            type: Array
        },
        paginator: {
            type: Object
        },
        pageSize: {
            type: Array
        }
    },
    data(){
        return{
            fields: [
                { key: 'id', label: '#', sortable: true},
                { key: 'created_at', label: 'Fecha', sortable: true},
                { key: 'nombre_departamento_pedido', label: 'Depto', sortable: true},
                { key: 'nombre_ciudad_pedido', label: 'Ciudad', sortable: true},
                { key: 'total_precio', label: 'Precio Total', sortable: true},
                { key: 'actions', label: 'Acciones'},
            ]
        }
    },
    methods: {
        updatePageSize(){
            /* the page sizee return the current page to initial because we can't know if the current page have items asigned!! */
            this.paginator.page = 1;
            /* emit the page size update to get the new list of items*/
            this.$emit('get-pedidos');
        },
        changePage(){
            this.$emit('change-page', this.paginator.page);            
        }
    }
};

</script>
<style scoped>

.card-header{
    background-color: none !important
}
</style>