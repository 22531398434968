<template>
    <div class="py-5">
        <FilterForm 
            :filter="filter" 
            @get-pedidos="getPedidos()" 
            @clear-filter-form="clearFilterForm()" 
        />

        <PedidosTable 
            :pedidos="pedidos" 
            :paginator="paginator" 
            :pageSize="pageSize" 
            @get-pedidos="getPedidos()" 
            @change-page="changePage($event)" 
        />
        
    </div>
</template>
<script>
import FilterForm from '../components/FilterForm.vue'
import PedidosTable from '../components/PedidosTable.vue'
import { mapMutations } from 'vuex'
export default {
    data(){
        return{
            pedidos: [],
            paginator: {
                page: 1,
                totalPage: 1,
                pageSize: 10,
            },
            pageSize: [10, 25, 50, 100],
            pageOptions: "",
            filter: {
                pedidoId: '',
                dateFrom: "",
                dateTo: new Date().toISOString().substr(0, 10),
            },
            
        }
    },
    components: {
        FilterForm,
        PedidosTable
        
    },
    created(){
        this.getPedidos();
    },
    methods:{
        ...mapMutations(['setLoading']),
        async getPedidos() {
            this.pageOptions = { 
                pageNumber:   this.paginator.page, 
                pageSize:     this.paginator.pageSize,
                pedidoId:     this.filter.pedidoId,
                dateFrom:     this.filter.dateFrom,
                dateTo:       this.filter.dateTo,
            }
            this.setLoading(true)
            await this.$conectar.post('pedidos-cliente-web/get-pedidos', this.pageOptions)
            .then(response =>{
                this.pedidos = response.data.pedidosLists;
                this.paginator.totalPage = response.data.totalPages;
            })
            .catch(e => {
                console.log(e.response);
            })
            this.setLoading(false)
        },
        changePage(page){
            this.paginator.page = page;
            this.getPedidos();
        },
        clearFilterForm(){
            this.filter.pedidoId = "";
            this.filter.dateFrom = "";
            this.filter.dateTo = new Date().toISOString().substr(0, 10);
            this.paginator.pageSize = 10;
        },
    }
}
</script>

<style scoped>
.singleLine th {
  white-space: nowrap !important;
  overflow: hidden !important;
}
.singleLineTd td {
  white-space: nowrap !important;
  overflow: hidden !important;
}
</style>